/* poppins */
@font-face {
  font-family: "Poppins";
  font-weight: 100;
  src: url("../fonts/poppins/Poppins-Thin.woff") format(woff);
}
@font-face {
  font-family: "Poppins";
  font-weight: 200;
  src: url("../fonts/poppins/Poppins-ExtraLight.woff") format(woff);
}
@font-face {
  font-family: "Poppins";
  font-weight: 300;
  src: url("../fonts/poppins/Poppins-Light.woff") format(woff);
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: url("../fonts/poppins/Poppins-Regular.woff") format(woff);
}
@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: url("../fonts/poppins/Poppins-Medium.woff") format(woff);
}
@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: url("../fonts/poppins/Poppins-SemiBold.woff") format(woff);
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: url("../fonts/poppins/Poppins-Bold.woff") format(woff);
}
@font-face {
  font-family: "Poppins";
  font-weight: 800;
  src: url("../fonts/poppins/Poppins-ExtraBold.woff") format(woff);
}
@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: url("../fonts/poppins/Poppins-Black.woff") format(woff);
}

/* gothic a1 */
@font-face {
  font-family: "GothicA1";
  font-weight: 900;
  src: url("../fonts/gothicA1/GothicA1-Black.woff") format(woff);
}
@font-face {
  font-family: "GothicA1";
  font-weight: 800;
  src: url("../fonts/gothicA1/GothicA1-ExtraBold.woff") format(woff);
}
@font-face {
  font-family: "GothicA1";
  font-weight: 700;
  src: url("../fonts/gothicA1/GothicA1-Bold.woff") format(woff);
}
@font-face {
  font-family: "GothicA1";
  font-weight: 600;
  src: url("../fonts/gothicA1/GothicA1-SemiBold.woff") format(woff);
}
@font-face {
  font-family: "GothicA1";
  font-weight: 500;
  src: url("../fonts/gothicA1/GothicA1-Medium.woff") format(woff);
}
@font-face {
  font-family: "GothicA1";
  font-weight: 400;
  src: url("../fonts/gothicA1/GothicA1-Regular.woff") format(woff);
}
@font-face {
  font-family: "GothicA1";
  font-weight: 300;
  src: url("../fonts/gothicA1/GothicA1-Light.woff") format(woff);
}
@font-face {
  font-family: "GothicA1";
  font-weight: 200;
  src: url("../fonts/gothicA1/GothicA1-ExtraLight.woff") format(woff);
}
@font-face {
  font-family: "GothicA1";
  font-weight: 100;
  src: url("../fonts/gothicA1/GothicA1-Thin.woff") format(woff);
}

@font-face {
  font-family: "SpoqaHanSansNeo";
  font-weight: 700;
  src: url("../fonts/spoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff") format(woff);
}
@font-face {
  font-family: "SpoqaHanSansNeo";
  font-weight: 500;
  src: url("../fonts/spoqaHanSansNeo/SpoqaHanSansNeo-Medium.woff") format(woff);
}
@font-face {
  font-family: "SpoqaHanSansNeo";
  font-weight: 400;
  src: url("../fonts/spoqaHanSansNeo/SpoqaHanSansNeo-Regular.woff") format(woff);
}
@font-face {
  font-family: "SpoqaHanSansNeo";
  font-weight: 300;
  src: url("../fonts/spoqaHanSansNeo/SpoqaHanSansNeo-Light.woff") format(woff);
}
@font-face {
  font-family: "SpoqaHanSansNeo";
  font-weight: 100;
  src: url("../fonts/spoqaHanSansNeo/SpoqaHanSansNeo-Thin.woff") format(woff);
}

@font-face {
  font-family: "NunitoSans";
  font-weight: 200;
  src: url("../fonts/nunitoSans/NunitoSans-ExtraLight.woff") format(woff);
}
@font-face {
  font-family: "NunitoSans";
  font-weight: 300;
  src: url("../fonts/nunitoSans/NunitoSans-Light.woff") format(woff);
}
@font-face {
  font-family: "NunitoSans";
  font-weight: 400;
  src: url("../fonts/nunitoSans/NunitoSans-Regular.woff") format(woff);
}
@font-face {
  font-family: "NunitoSans";
  font-weight: 500;
  src: url("../fonts/nunitoSans/NunitoSans-Medium.woff") format(woff);
}
@font-face {
  font-family: "NunitoSans";
  font-weight: 600;
  src: url("../fonts/nunitoSans/NunitoSans-SemiBold.woff") format(woff);
}
@font-face {
  font-family: "NunitoSans";
  font-weight: 700;
  src: url("../fonts/nunitoSans/NunitoSans-Bold.woff") format(woff);
}
@font-face {
  font-family: "NunitoSans";
  font-weight: 800;
  src: url("../fonts/nunitoSans/NunitoSans-ExtraBold.woff") format(woff);
}
@font-face {
  font-family: "NunitoSans";
  font-weight: 900;
  src: url("../fonts/nunitoSans/NunitoSans-Black.woff") format(woff);
}

@font-face {
  font-family: "tmonmonsori";
  font-weight: 900;
  src: url("../fonts/tmon/TmonMonsori.otf.woff") format(woff);
}

@font-face {
  font-family: "NotoSans";
  font-weight: 100;
  src: url("../fonts/NotoSans/NotoSansKR-Thin.woff") format(woff);
}
@font-face {
  font-family: "NotoSans";
  font-weight: 300;
  src: url("../fonts/NotoSans/NotoSansKR-Light.woff") format(woff);
}
@font-face {
  font-family: "NotoSans";
  font-weight: 400;
  src: url("../fonts/NotoSans/NotoSansKR-Regular.woff") format(woff);
}
@font-face {
  font-family: "NotoSans";
  font-weight: 500;
  src: url("../fonts/NotoSans/NotoSansKR-Medium.woff") format(woff);
}
@font-face {
  font-family: "NotoSans";
  font-weight: 700;
  src: url("../fonts/NotoSans/NotoSansKR-Bold.woff") format(woff);
}
@font-face {
  font-family: "NotoSans";
  font-weight: 900;
  src: url("../fonts/NotoSans/NotoSansKR-Black.woff") format(woff);
}

@font-face {
  font-family: "EbsFont";
  font-weight: 600;
  src: url("../fonts/ebs/ebs_font.woff") format(woff);
}

/* suite */
@font-face {
  font-family: "SUITE";
  font-weight: 300;
  src: url("../fonts/SUITE/SUITE-Light.woff2") format("woff2");
}
@font-face {
  font-family: "SUITE";
  font-weight: 400;
  src: url("../fonts/SUITE/SUITE-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "SUITE";
  font-weight: 500;
  src: url("../fonts/SUITE/SUITE-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "SUITE";
  font-weight: 600;
  src: url("../fonts/SUITE/SUITE-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "SUITE";
  font-weight: 700;
  src: url("../fonts/SUITE/SUITE-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "SUITE";
  font-weight: 800;
  src: url("../fonts/SUITE/SUITE-ExtraBold.woff2") format("woff2");
}
@font-face {
  font-family: "SUITE";
  font-weight: 900;
  src: url("../fonts/SUITE/SUITE-Heavy.woff2") format("woff2");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 900;
  font-display: swap;
  src: local("Pretendard Black"),
    url("../fonts/pretendard/woff2/Pretendard-Black.woff2") format("woff2"),
    url("../fonts/pretendard/woff/Pretendard-Black.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 800;
  font-display: swap;
  src: local("Pretendard ExtraBold"),
    url("../fonts/pretendard/woff2/Pretendard-ExtraBold.woff2") format("woff2"),
    url("../fonts/pretendard/woff/Pretendard-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 700;
  font-display: swap;
  src: local("Pretendard Bold"),
    url("../fonts/pretendard/woff2/Pretendard-Bold.woff2") format("woff2"),
    url("../fonts/pretendard/woff/Pretendard-Bold.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 600;
  font-display: swap;
  src: local("Pretendard SemiBold"),
    url("../fonts/pretendard/woff2/Pretendard-SemiBold.woff2") format("woff2"),
    url("../fonts/pretendard/woff/Pretendard-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 500;
  font-display: swap;
  src: local("Pretendard Medium"),
    url("../fonts/pretendard/woff2/Pretendard-Medium.woff2") format("woff2"),
    url("../fonts/pretendard/woff/Pretendard-Medium.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  font-display: swap;
  src: local("Pretendard Regular"),
    url("../fonts/pretendard/woff2/Pretendard-Regular.woff2") format("woff2"),
    url("../fonts/pretendard/woff/Pretendard-Regular.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 300;
  font-display: swap;
  src: local("Pretendard Light"),
    url("../fonts/pretendard/woff2/Pretendard-Light.woff2") format("woff2"),
    url("../fonts/pretendard/woff/Pretendard-Light.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 200;
  font-display: swap;
  src: local("Pretendard ExtraLight"),
    url("../fonts/pretendard/woff2/Pretendard-ExtraLight.woff2") format("woff2"),
    url("../fonts/pretendard/woff/Pretendard-ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 100;
  font-display: swap;
  src: local("Pretendard Thin"),
    url("../fonts/pretendard/woff2/Pretendard-Thin.woff2") format("woff2"),
    url("../fonts/pretendard/woff/Pretendard-Thin.woff") format("woff");
}
